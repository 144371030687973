//
// locales/glitch/br.js
// automatically generated by generateLocalePacks.js
//

import messages from '../../../app/javascript/flavours/glitch/locales/br';
import localeData from 'react-intl/locale-data/br.js';
import { setLocale } from 'locales';

setLocale({
  localeData,
  messages,
});
